.App {
  text-align: center;
}

.App-link {
  color: #09d3ac;
}

.App {
  color: #b11a21;
}


.give-space {
  margin: 50px 0 50px 0;
}

.top-space {
  margin-top: 50px;
}