.hearings {
	background-color: hsl(0, 0%, 96%);
	padding-top: 50px;
	padding-bottom: 50px;
}

.content {
  width: 48%;
  height: 400px; 
  padding-right: .5%;
  padding-left: .5%;
}

@media (pointer: coarse) and (hover: none) {
  .content{
    height: 300px;
    width: 300px;
  }
}

.videos {
  margin: 0 auto;
}