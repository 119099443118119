.about-section {
  min-height: 400px;
}

.video {
  width: 660; 
  margin: 0 auto,
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .video {
  width: 100%;
  }
}
header {
  position: relative;
  background-image: url('./holding_hand.jpg');
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  min-height: 25rem;
  width: 100%;
}

header video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(0%);
  -moz-transform: translateX(-50%) translateY(0%);
  -webkit-transform: translateX(-50%) translateY(0%);
  transform: translateX(-25%) translateY(-0%);
}

header .container {
  position: relative;
  z-index: 2;
}

header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
}

.mainVideo{
  padding-top: 20px;
  min-width: 300px;
  max-width: 600px;
  height: 400px; 
  margin: 0 auto;
}

@media (pointer: coarse) and (hover: none) {
  header {
    background: url('./holding_hand.jpg') black no-repeat cover;
  }
  header video {
    display: none;
  }
  .mainVideo{
    height: 300px;
    width: 300px;
  }
}