.card {
	width: 47.5%;
	float: left;
	height: 375px;
	margin-bottom: 10px;
	margin-left: 1.25%;
	margin-right: 1.25%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .card {
	width: 100%;
  }
}

.icons{
	transition: .2s;
	padding: 10px 10px;
}

.icons:hover {
	transform: scale(1.2);
}